import * as _shams2 from "has-tostringtag/shams";

var _shams = "default" in _shams2 ? _shams2.default : _shams2;

var exports = {};
var toStr = Object.prototype.toString;
var fnToStr = Function.prototype.toString;
var isFnRegex = /^\s*(?:function)?\*/;

var hasToStringTag = _shams();

var getProto = Object.getPrototypeOf;

var getGeneratorFunc = function () {
  // eslint-disable-line consistent-return
  if (!hasToStringTag) {
    return false;
  }

  try {
    return Function("return function*() {}")();
  } catch (e) {}
};

var GeneratorFunction;

exports = function isGeneratorFunction(fn) {
  if (typeof fn !== "function") {
    return false;
  }

  if (isFnRegex.test(fnToStr.call(fn))) {
    return true;
  }

  if (!hasToStringTag) {
    var str = toStr.call(fn);
    return str === "[object GeneratorFunction]";
  }

  if (!getProto) {
    return false;
  }

  if (typeof GeneratorFunction === "undefined") {
    var generatorFunc = getGeneratorFunc();
    GeneratorFunction = generatorFunc ? getProto(generatorFunc) : false;
  }

  return getProto(fn) === GeneratorFunction;
};

export default exports;